$(document).ready(function () {
  zE('messenger', 'hide');
  zE('messenger:on', 'close', function() {
    zE('messenger', 'hide');
  });

  $('#help-btn').click(function() {
    zE('messenger', 'show');
    zE('messenger', 'open');
  })
});